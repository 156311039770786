import React, { useEffect, useState } from 'react';

const MySites = () => {
    const sites = [
        { name: "Le Musée de la Porte", source: "lmdlp.png", link: "https://lemuseedelaporte.fr/localisation", info: "Missionné par les frères IVORRA, j'ai eu l'honneur de concrétiser leur projet de site afin de diffuser leur amour pour l'ingéniosité humaine. Un composant dynamique vérifie instantanément si le musée est ouvert lors de la visite. Un système de traduction a aussi été implémenté." },
        { name: "KRZ", source: "krz.png", link: "/Building", info: "Un design pensé aux côtés du musicien et compositeur Krzyzanski Thomas. Le site intègre une partie privée permettant à son propriétaire de rajouter du contenu. Actuellement en refonte pour un changement de thème." },
        { name: "Kasa", source: "kasa.png", link: "https://aneil-kasa-preview.netlify.app/", info: "Refonte complète du site Kasa en JavaScript avec React, incluant React Router ainsi que l'intégration de maquettes Figma respectant les guidelines de codage. Des données JSON ont été utilisées afin de simuler un backend. Immersion en tant que freelance." },
        { name: "Oh My Food !", source: "ohmyfood.png", link: "https://joyfulcanis.github.io/ohmyfood/index.html", info: `Développement d'un site "mobile first" et responsive pour explorer et réserver des menus de restaurants gastronomiques, intégrant des animations fluide grâce aux keyframes et une gestion efficace du style avec Sass.` },
        { name: "Ce Site !", source: "thissite.png", link: "https://joyfulcanis.github.io/booki/index.html", info: `Ce site est le fruit de mon engagement à cultiver un espace numérique dont les racines plongent dans le sol fertile de la créativité, proche du fleuve de partage et de curiosité.` },
        { name: "Booki", source: "booki.png", link: "https://joyfulcanis.github.io/booki/index.html", info: `Le tout premier projet en HTML et CSS conçu dans le cadre d'un début de formation, accompagné de Nathan Akpawu, développeur et mentor que je remercie infiniment.` },



    ];

    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.semi-tanosable');
            const scrollPosition = window.scrollY;

            elements.forEach(element => {
                const rect = element.getBoundingClientRect();
                if (rect.top <= -80) {
                    element.classList.add('fade-out');
                    element.classList.remove('fade-in');
                } else {
                    element.classList.add('fade-in');
                    element.classList.remove('fade-out');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="experience-sites">
            {
                sites.map((el, index) => (
                    <a key={`site${index}`} draggable="false" target='_blank' rel="noreferrer" className='semi-tanosable' href={el.link}>
                        <div data-info={el.info} className='experience-sites__site semi-tanosable'>
                            <h4 className="experience-sites__site__title semi-tanosable">{el.name}</h4>
                            <img draggable="false" src={`/img/sites/${el.source}`} alt={el.name} className="experience-sites__site__img semi-tanosable" />
                        </div>
                    </a>
                ))
            }
        </div>
    );
};

export default MySites;
