import React, { useEffect } from 'react';

const Music = () => {
    
    const profiles = ["https://soundcloud.com/user-907099802", "https://soundcloud.com/laetuscanis"]
    const musics = [
        { name: "XY-Zen", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802871575&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[0] },
        { name: "K", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/288010326&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[1] },
        { name: "Remarquable Clarification", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802917406&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[0] },
        { name: "Welcome to Iraya", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802850545&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[0] },
        { name: "Nox", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/329740683&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[1] },
        { name: "Goodbye Mr Canis", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/305882118&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[1] },
        { name: "Attention: Chien Jaune", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/315561519&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[1] },
        { name: "Nautilus", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1740335862&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[0] },
        { name: "I Left Mars for Venus", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802877374&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[0] },
        { name: "N <3 A But A Doesn't Give a F", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802891072&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[0] },
        { name: "Gates of Cyclothymic U", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/802892017&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[0] },
        { name: "Complex Umbras", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/347076583&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[1] },
        { name: "Grey Mechanics", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/321646638&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[1] },
        { name: "Helios One", url: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/354722726&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true", profile: profiles[1] },

    ];



    return (
        <div className='music-box'>
            
            <img draggable="false" src="/img/illustrations/musique.png" alt="" className="home__main__img-s4 tanosable" />
            <img draggable="false" src="/img/illustrations/guitare.png" alt="" className="home__main__img-s3 tanosable" />
            {musics.map((music, index) => (
                <div key={index} className='music-box__container tanosable' >
                    <iframe
                        title="SoundCloud Player"
                        width="180px"
                        height="180px"
                        frameborder="no"
                        allow="autoplay"
                        src={music.url}
                    ></iframe>
                    <div style={{
                        textAlign: 'center',
                        fontSize: '10px',
                        color: '#cccccc',
                        lineBreak: 'anywhere',
                        wordBreak: 'normal',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        fontFamily: 'venitadoremusstraight',
                        fontWeight: '100'
                    }}>
                        <a href={music.profile} rel="noreferrer" title="Laetus Canis" target="_blank" style={{ color: '#cccccc', textDecoration: 'none' }}></a>
                        <a rel="noreferrer" className="music-name" href={music.url} title={music.name} target="_blank" style={{ color: 'white', textDecoration: 'none' }}>{music.name}</a>
                    </div>
                </div>
            ))
            
            }

        </div>

    );
};

export default Music;



