import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import CodeList from '../components/CodeList';
import MySites from '../components/MySites';
import Music from '../components/Music';
import Footer from '../components/Footer';
import Constructing from '../components/Constructing';
import Games from '../components/Games';

const Home = () => {


    const [isScrollDown, setIsScrollDown] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setIsScrollDown(true);
            } else {
                setIsScrollDown(false);
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll,);
        };
    }, []);

    //Fadein-Fadeout

    useEffect(() => {
        const handleScroll = () => {
            //selecting the elements to make them
            const elements = document.querySelectorAll('.tanosable');

            elements.forEach(element => {
                const rect = element.getBoundingClientRect();
                if (rect.top <= 100) {
                    element.classList.add('fade-out');
                    element.classList.remove('fade-in');
                } else {
                    element.classList.add('fade-in');
                    element.classList.remove('fade-out');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    return (
        <div className='home'>
            <Header />
            <main className={`home__main ${isScrollDown ? "home__main--sticky" : ""}`}>
                
                <h2 className="regular-title tanosable">Developpement Web</h2>

                <div className='scroll-down-box tanosable'>
                    <a href='/#competences'>
                        <img src="/img/icons/chevron1.png" alt="futuristic chevron" className="scroll-down-box__chev1 " />
                    </a>
                </div>
                <img draggable="false" src="/img/illustrations/developpeur.png" alt="" className="home__main__img-s1 semi-tanosable" />
                <div className='regular-card'>
                    
                    <h3 className='regular-card__title tanosable' id="competences">COMPETENCES</h3>
                    <div className='regular-bar tanosable'></div>

                        
                    <CodeList />
                    
                </div>


                
                <div className='regular-card'>
                <img draggable="false" src="/img/illustrations/detective.png" alt="" className="home__main__img-s2 semi-tanosable" />
                    <h3 className='regular-card__title tanosable' id="experiences">EXPERIENCES</h3>
                    
                    <div className='regular-bar tanosable'></div>
                    <h4 className='music-box-title tanosable'>Sites</h4>
                    <MySites />

                    <h3 className='regular-card__title tanosable' id="experiences">EXTRAS</h3>
                    
                    <div className='regular-bar tanosable'></div>
                    <h4 className='music-box-title tanosable'>Musiques</h4>
                    <Music />

                    <div className='regular-bar margin-top tanosable'></div>
                    <h4 className='game-box__title tanosable'>Jeux</h4>
                    <Games />

                </div>


            <Constructing />
            <img draggable="false" src="/img/illustrations/amoureux.png" alt="" className="home__main__img-thanks semi-tanosable" />
            <h3 className='thanks-title'>Merci de votre visite !</h3>
            </main>

            <Footer />
        </div>
    );
};

export default Home;


//!!!!!!!!!!!! DES JEUX POUR LES SOFT SKILLS !!!/////////////