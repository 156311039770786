import React, { useState } from 'react';

const CodeList = () => {
    const languages = [
        { name: 'html5', text: "Expérience pratique en HTML5 pour la mise en place de sites web dynamiques accessibles dans les normes W3C." },
        { name: 'css3', text: "Aptitude à intégrer des effets visuels élaborés en utilisant les fonctionnalités avancées de CSS3, telles que les dégradés, les ombres, les keyframes ainsi que des compétence dans l'utilisation du préprocesseurs  Sass." },
        { name: 'javascript', text: "Capacités à concevoir et réaliser des solutions robustes et innovantes à l'aide de JavaScript pour le déploiement d'applications web interactives et performantes." },
        { name: 'react', text: "Construction de composants réutilisables dans le cadre de single page applications, parfaitement adaptés aux exigences de chaque projet" },
        { name: 'lua', text: "Travaillant sur l'implémentation de fonctionnalités de base à l'aide de Lua afin d'élargir mon domaine de compétences." }
    ];

    const [deployedStates, setDeployedStates] = useState({
        html5: false,
        css3: false,
        javascript: false,
        react: false,
        lua: false
    });

    const handleClick = (name) => {
        setDeployedStates(prevStates => ({
            ...prevStates,
            [name]: !prevStates[name]
        }));
    };

    return (
        <div className='list-box'>
            <ul className="code-list">
                {languages.map((language, index) => (
                    <li key={index} className='tanosable'>
                        <button className={`code-list__code tanosable ${deployedStates[language.name] ? 'code-list__code--deployed' : ''}`} onClick={() => handleClick(language.name)}>
                            <div className="code-list__element">
                                <img alt={`${language.name} logo`} draggable="false" className="code-list__element__icon" src={`/img/icons/${language.name}.png`} />
                            </div>
                            <h4 className="code-list__title">{language.name}</h4>
                            <span className={`code-list__chev  ${deployedStates[language.name] ? 'code-list__chev--active' : ''}`}>&gt;</span>
                        </button>
                        <div className={`code-list__text-box tanosable ${deployedStates[language.name] ? 'code-list__text-box--deployed' : ''}`}>
                            <p className={`code-list__text-box__text tanosable ${deployedStates[language.name] ? 'code-list__text-box__text--deployed' : ''}`}>{language.text}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CodeList;
