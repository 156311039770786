import React from 'react';

const Footer = () => {
    return (
        <footer className='footer'>
            
            <img draggable="false" src="/img/illustrations/furtivement3.png" alt="" className="home__main__img-footer" />
        </footer>
    );
};

export default Footer;