import React, { useEffect, useState } from 'react';

const Header = () => {
    const sections = ["competences","contact", "experiences" ]

    const [isScrollDown, setIsScrollDown] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setIsScrollDown(true);
            } else {
                setIsScrollDown(false);
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll,);
        };
    }, []);




    return (
        <header className={`header ${isScrollDown ? 'header--sticky' : ''}`}>
            <img draggable="false" src="/img/illustrations/furtivement.png" alt="" className="home__main__img-header" />
            <button className={`banner ${isScrollDown ? 'banner--sticky' : ''}`}>

                <a draggable="false" href='/#'>
                    <img draggable="false" className='banner__img' src='/img/bannerb.png' alt="grande planète surblombant des montagnes et une forêt" />
                    <span className={`banner__chevron ${isScrollDown ? 'banner__chevron--sticky' : ''}`}>&gt;</span>
                </a>
            </button>
            <nav className={`header__nav ${isScrollDown ? "header__nav--sticky" : ""}`}>
                <ul className="header__nav__list">
                    
                    {
                        sections.map((el, index) =>{
                            return(
                                <li key={`nav-${index}`}className="header__nav__list__element">
                                    <a draggable="false" href={`/#${el}`}>{el}</a></li>
                            )
                        })
                    }
                </ul>
            </nav>
            <div className={`title-box ${isScrollDown ? "title-box-stikcy" : ""}`}>
                <h1 className={`title-box__title ${isScrollDown ? "title-stikcy" : ""}`}>Aloulou</h1>
                <h1 className={`title-box__title ${isScrollDown ? "title-sticky" : ""}`}>Neil</h1>
            </div>
        </header>
    );
};

export default Header;