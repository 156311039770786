import React from 'react';

const Constructing = () => {
    return (
        <div className='chantier '>
            
            <img draggable="false" src="/img/illustrations/plombier.png" alt="" className="chantier__img semi-tanosable" />
            <h3 className='chantier__title tanosable'>Ce site est encore en contrsuction, ne faites pas attention à nous !</h3>
            
        </div>
    );
};

export default Constructing;