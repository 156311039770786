import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const NotFound = () => {
    return (
        <div>
            <Header />
            <main className='not-found'>
                <h2 className='regular-title not-found__title tanosable'>404</h2>
                <p className='regular-text tanosable'>Oh no...</p>
                <a href='/'><button className='building__back not-found__back tanosable'>RETOUR</button></a>
                
            </main>
            <Footer />
            
        </div>
    );
};

export default NotFound;