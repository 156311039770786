import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Constructing from '../components/Constructing';

const Building = () => {
    return (
        <div className='building'>
            <Header />
            <main className='building__main'>
                <p className='building__main__text-up'>Le projet demandé est probablement en chantier 🚧</p>
            <h2 className='building__main__title regular-title'>PAS DE PANIQUE !</h2>
            <p className='building__main__text-down'>Vous pouvez me <a className='building__contact' href='/#contact'>contacter</a> si vous souhaitez accéder au code et qu'il n'est pas disponible sur <a className='building__git' target='_blank' rel="noreferrer" href='https://github.com/JoyfulCanis'>Github</a> ou si vous souhaitez simplement en apprendre d'avantage !</p>
            <a href='/'><button className='building__back'>RETOUR</button></a>
            
            <Constructing />
            </main>

            <Footer />
        </div>
    );
};

export default Building;