import React from 'react';

const Games = () => {
    return (
        <div className='games'>
            <img draggable="false" src="/img/illustrations/jeu-video.png" alt="" className="home__main__img-games tanosable" />
            <img draggable="false" src="/img/illustrations/lunettes-de-realite-virtuelle.png" alt="" className="home__main__img-games2 tanosable" />
            
        </div>
    );
};

export default Games;